<template>
  <div class="buy-rebate-order-page">
    <div class="timeChoiceDiv flexbox flex-lr flex-align-center">
      <div class="timeAndBtn flexbox flex-lr flex-align-center mr20">
        <el-input v-model="form.orderId" placeholder="请输入订单编号" style="width:200px;" clearable></el-input>
        <el-input v-model="form.shopName" placeholder="请输入店铺名称" style="width:160px;" clearable class="ml20"></el-input>
        <el-input v-model="form.customerTel" placeholder="请输入客户电话" style="width:160px;" clearable class="ml20"></el-input>
        <el-date-picker v-model="time" class="ml20" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
      </div>
      <el-button type="primary" class="sure" @click="search">筛选</el-button>
      <el-button type="info" class="sure" @click="clear">清空</el-button>

      <PrevReclick>
        <el-button type="primary" class="sure" @click="exportExcle">导出</el-button>
      </PrevReclick>
    </div>
    <div class="orderDetail">
      <span>订单总笔数：{{orderNum}}</span>
      <span>实付总金额：{{orderAmount}}</span>
    </div>
    <div class="tableBox">
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column prop="orderId" label="订单编号" width="180"></el-table-column>
        <el-table-column prop="shopName" label="店铺名称"></el-table-column>
        <el-table-column prop="customerTel" label="客户电话"></el-table-column>
        <el-table-column prop="payId" label="支付订单号"></el-table-column>
        <el-table-column prop="originalAmount" label="订单金额"></el-table-column>
        <el-table-column prop="userPayAmount" label="实际支付金额"></el-table-column>
        <el-table-column prop="payTime" label="支付时间" width="160">
          <template slot-scope="scope">
            <span>{{ $formatDate(scope.row.payTime, 'yyyy-MM-dd HH:mm:ss') }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="订单状态" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 0"> 已支付</span>
            <span v-else-if="scope.row.status == 1||scope.row.status == 2"> 退款中 </span>
            <span v-else-if="scope.row.status == 3||scope.row.status == 5">退款失败</span>
            <span v-else-if="scope.row.status == 4">已退款</span>
          </template>
        </el-table-column>
        <el-table-column prop="profitAmount" label="返佣金额"></el-table-column>

        <el-table-column prop="goodCount" label="订单详情" align="center" width="120">
          <template slot-scope="scope">
            <div style="text-align:center;color:#ef3f46;cursor: pointer;" @click="openDetail(scope.row)">详情</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination class="mt10" background :page-count="totalPage" :current-page.sync="currentPage" @current-change="pageChange">
    </el-pagination>
    <el-dialog class="advert-detail-dialog" :visible="orderVisible" width="780px" @close="orderVisible=false" title="订单详情">
      <aside class="order-detail-content">
        <section class="shop-data">
          <ul>
            <li v-for="(item,index) in orderLableList" :key="index" class="shop-data-li">
              {{item.name}}：{{item.remark?item.remark:''}}{{orderDetailResolve(item)}}</li>
          </ul>
          <ul>
            <li v-for="(item,index) in orderLableList1" :key="index" class="shop-data-li">
              {{item.name}}：{{item.remark?item.remark:''}}{{orderDetailResolve(item)}}{{item.session?item.session:''}}</li>
          </ul>
        </section>
      </aside>
      <p style="text-align:center;font-size: 18px;color: #303133;line-height:40px">分润明细</p>
      <p style="font-size: 14px;color: #444444;line-height:40px">分润总金额:{{orderDetail.status==4?0:orderDetail['profitAmount']}}</p>
      <el-table :data="shareProfitDtos" style="width: 100%" border>
        <el-table-column prop="name" label="名称" width="180"></el-table-column>
        <el-table-column prop="type" label="身份类型"></el-table-column>
        <el-table-column prop="tel" label="账号"></el-table-column>
        <el-table-column prop="profitScale" label="分润比例(%)">
          <template slot-scope="scope">
            <span>{{((+scope.row.profitScale))+'%'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="profitAmount" label="分润金额(元)">
          <template slot-scope="scope">
            <span>{{((orderDetail.status==4?0:scope.row.profitAmount))}}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
import {
  $sp_rebateOrder, $sp_getPlatformOrderTotal, $sp_getPlatformOrderDetail,
} from '@/api/buyRebate';
import { exportFileSync } from '@base/utils';

const orderState = ['已完成', '退款中', '退款中', '退款失败', '已退款', '退款失败'];
const orderLableList = [{ name: '店铺名称', type: 'shopName' }, { name: '订单号', type: 'orderId' },
  { name: '支付时间', type: 'payTime' }, { name: '客户电话', type: 'customerTel' }, { name: '订单状态', type: 'status' },
  { name: '订单原价', type: 'originalAmount', remark: '￥' },
];
const orderLableList1 = [{ name: '不参与优惠金额', type: 'originalAmountNoJoin', remark: '￥' },

  { name: '不参与优惠金额抽佣比例', type: 'noCommission', session: '%' },
  { name: '参与优惠金额', type: 'originalAmountJoin', remark: '￥' },
  { name: '用户优惠折扣', type: 'customerDiscount', session: '%' },
  { name: '商户结算比例', type: 'shopDiscount', session: '%' },
  { name: '用户实付金额', type: 'userPayAmount', remark: '￥' },
  { name: '商户结算金额', type: 'totalCout', remark: '￥' },
  { name: '订单总分润金额', type: 'profitAmount', remark: '￥' }];

export default {
  data() {
    return {
      form: {
        orderId: '',
        shopName: '',
        customerTel: '',
      },
      time: '',
      totalPage: 1,
      currentPage: 1,
      tableData: [],
      orderAmount: 0,
      orderNum: 0,
      orderVisible: false,
      orderLableList,
      orderLableList1,
      orderDetail: {},
      orderState,
      shareProfitDtos: [],
    };
  },
  created() {
    this.getTableData();
  },
  mounted() {

  },
  methods: {
    fliterData(items) {
      const item = JSON.parse(JSON.stringify(items));
      let str;
      if (this.orderDetail.status === 4) {
        if (item.unit) {
          str = `${((+this.orderDetail[item.type]) * 100).toFixed(2) * 1}%`;
        } else {
          str = item.value;
        }
      }
      if (this.orderDetail.status !== 4) {
        if (item.unit) {
          if (this.orderDetail[item.type]) {
            str = `${((+this.orderDetail[item.type]) * 100).toFixed(2) * 1}%`;
          } else {
            str = '0%';
          }
        } else {
          str = this.orderDetail[item.type] ? `￥${this.orderDetail[item.type]}` : item.value;
        }
      }

      return str;
    },
    orderDetailResolve(item) {
      if (this.orderDetail.status === 4) {
        if (item.type === 'status') {
          return this.orderState[this.orderDetail.status];
        } else if (item.type === 'profitAmount') {
          return 0;
        } else {
          return this.orderDetail[item.type];
        }
      } else if (item.type === 'status') {
        return this.orderState[this.orderDetail.status];
      } else {
        return this.orderDetail[item.type];
      }
    },
    openDetail(item) {
      $sp_getPlatformOrderDetail({ orderId: item.orderId }).then((res) => {
        this.orderVisible = true;
        this.orderDetail = res || {};
        this.orderDetail.totalCout = this.totalNum(res);
        if (this.orderDetail.payTime) {
          this.orderDetail.payTime = this.$formatDate(this.orderDetail.payTime, 'yyyy-MM-dd HH:mm:ss');
        }
        this.shareProfitDtos = res.shareProfitDtos;
      });
    },
    totalNum(item) {
      const {
        shopDiscount, originalAmountJoin, originalAmountNoJoin, noCommission, 
      } = item;
      if (shopDiscount ?? originalAmountJoin ?? originalAmountNoJoin ?? noCommission) {
        const num = ((+originalAmountJoin) * (shopDiscount / 100) + (originalAmountNoJoin) * (1 - noCommission / 100)).toFixed(2) * 1;
        console.log(num, '3344');
        return num;
      } else {
        return '';
      }
    },
    // 导出列表
    exportExcle() {
      // if ((!this.form.query) && !this.time) {
      //   this.$message.error('请输入店铺名称账号，或者日期');
      //   return;
      // }
      let startTime = null; let endTime = null;
      if (this.time) {
        startTime = (this.time[0]).getTime() || null; endTime = (this.time[1]).getTime() || null;
      }
      // $sp_exportExcle({ query: this.form.query, startTime, endTime }).then(() => {

      // });
      exportFileSync(
        '/j/rebate/rebateOrder/PlatformOrdersExcelOutPut',
        {
          ...this.form,
          startTime,
          endTime,
        },
        'get',
        '订单.xlsx',
        this.onDownloadProgress,
      ).then(() => {
        // this.handleDownloadQrCodeLeftBtn();
      });
    },
    // 筛选
    search() {
      this.currentPage = 1;
      this.getTableData();
    },
    // 清空
    clear() {
      this.form = {
        orderId: '',
        shopName: '',
        customerTel: '',
      };
      this.currentPage = 1;
      this.time = '';
      this.getTableData();
    },
    // 请求列表数据
    getTableData() {
      const params = {
        pageCurrent: this.currentPage,
        pageSize: 15,
        ...this.form,
      };
      if (this.time) {
        params.startTime = (this.time[0]).getTime();
        params.endTime = (this.time[1]).getTime();
      } else {
        params.startTime = '';
        params.endTime = '';
      }
      // 请求订单数据
      $sp_rebateOrder(params).then((res) => {
        this.tableData = res.records;
        this.totalPage = res.pages;
      });
      // 请求订单汇总
      $sp_getPlatformOrderTotal(params).then((res) => {
        this.orderAmount = res.totalAmount || 0;
        this.orderNum = res.totalCount || 0;
      });
    },


    // 翻页
    pageChange() {
      this.getTableData();
    },
  },
};
</script>
<style lang="scss" >
.buy-rebate-order-page {
  width: 100%;
  min-width: 1400px;
  // height: 100%;
  margin: 0;
  padding: 40px 20px;
  box-sizing: border-box;
  background-color: #fff;
  .longwidth {
    width: 300px !important;
  }
  .orderDetail {
    width: 100%;
    margin-top: 40px;
    padding: 20px;
    box-sizing: border-box;
    background-color: #f0f0f0;
    font-size: 14px;
    color: #555;
    span:first-child {
      margin-right: 80px;
    }
  }
  .tableBox {
    width: 100%;
    margin-top: 40px;
  }
  .el-table {
    // max-height: 400px;
    // overflow-y:auto;
    & > th {
      background-color: #ef3f46;
      color: #fff;
    }
  }
  .el-dialog__header {
    text-align: center;
  }
  .el-dialog__body {
    color: #444444;
    padding: 15px 20px;
    .el-table {
      max-height: 400px;
      overflow-y: auto;
      & > th {
        background-color: #ef3f46;
        color: #fff;
      }
    }
  }
  .order-detail-content {
    // display: flex;
    // flex-flow: wrap;
    // justify-content: space-between;
    padding: 0 20px;
    .shop-data {
      display: flex;
      flex-flow: wrap;
      justify-content: space-around;
    }
    .shop-data-li {
      width: 220px;
    }
    .share-profits {
      display: flex;
      flex-flow: wrap;
    }
    li {
      line-height: 30px;
      width: 190px;
    }
  }
}
</style>

